import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
// import Header from "../components/organisms/header";
import DesignStory from "../components/molecules/design-story";
import * as designStyles from "../styles/design-styles.module.scss";

function DesignColor() {
  return (
    <div className="design__content">
      <SEO title="Design System - Color" />
      <h2>Color</h2>
      <p className="design__text p2">
        The full range of Buffy colors are ADA compliant and developed for the
        digital screen. While the primary colors should be utilized for most use
        cases, the expanded set of colors can be used for more complex
        appications like screen design. There are also monochrome colors for
        text, separators and inactive states. In addition, colors for success or
        error notifications are represented.
      </p>

      <div className="design__stories">
        {Object.entries(designStyles).map(([colorKey, colorValue]) => {
          if (colorValue.startsWith("#")) {
            return (
              <DesignStory
                title={`${colorKey}`}
                type="color"
                compact
                key={`${colorKey}_${colorValue}`}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default DesignColor;
